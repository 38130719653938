var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"browser_warning"},[_c('div',{staticClass:"clearfix"}),_c('h2',[_vm._v(_vm._s(_vm.$t("BrowserWarning.NotSupportedTitle")))]),_c('p',[_vm._v(_vm._s(_vm.$t("BrowserWarning.NotSupportedHelp")))]),_c('div',[_c('div',{staticClass:"col-sm-6 col-md-3"},[_c('div',{staticClass:"browser",attrs:{"id":"chrome"}},[_vm._m(0),_c('div',{staticClass:"available"},[_c('h4',[_vm._v(_vm._s(_vm.$t("BrowserWarning.AvailableFor")))]),_vm._m(1)])])]),_c('div',{staticClass:"col-sm-6 col-md-3"},[_c('div',{staticClass:"browser",attrs:{"id":"firefox"}},[_vm._m(2),_c('div',{staticClass:"available"},[_c('h4',[_vm._v(_vm._s(_vm.$t("BrowserWarning.AvailableFor")))]),_vm._m(3)])])]),_c('div',{staticClass:"col-sm-6 col-md-3"},[_c('div',{staticClass:"browser",attrs:{"id":"edge"}},[_vm._m(4),_c('div',{staticClass:"available"},[_c('h4',[_vm._v(_vm._s(_vm.$t("BrowserWarning.AvailableFor")))]),_vm._m(5)])])]),_c('div',{staticClass:"col-sm-6 col-md-3"},[_c('div',{staticClass:"browser",attrs:{"id":"safari"}},[_vm._m(6),_c('div',{staticClass:"available"},[_c('h4',[_vm._v(_vm._s(_vm.$t("BrowserWarning.AvailableFor")))]),_vm._m(7)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('h2',[_c('span'),_vm._v("Google Chrome")]),_c('div',{staticClass:"download"},[_c('a',{staticClass:"browserlink",attrs:{"href":"https://www.google.com/chrome/browser/desktop/","target":"_blank","data-track":"Google Chrome"}},[_vm._v("DOWNLOAD")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',{staticClass:"windows"},[_c('span',[_vm._v("Windows")])]),_c('li',{staticClass:"mac"},[_c('span',[_vm._v("Mac OS")])]),_c('li',{staticClass:"linux"},[_c('span',[_vm._v("Linux")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('h2',[_c('span'),_vm._v("Mozilla Firefox")]),_c('div',{staticClass:"download"},[_c('a',{staticClass:"browserlink",attrs:{"href":"http://www.mozilla.org/firefox/new/","target":"_blank","data-track":"Mozilla Firefox"}},[_vm._v("DOWNLOAD")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',{staticClass:"windows"},[_c('span',[_vm._v("Windows")])]),_c('li',{staticClass:"mac"},[_c('span',[_vm._v("Mac OS")])]),_c('li',{staticClass:"linux"},[_c('span',[_vm._v("Linux")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('h2',[_c('span'),_vm._v("Microsoft Edge")]),_c('div',{staticClass:"download"},[_c('a',{staticClass:"browserlink",attrs:{"href":"https://www.microsoft.com/en-ca/download/details.aspx?id=48126","target":"_blank","data-track":"Edge"}},[_vm._v("DOWNLOAD")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"single"},[_c('li',{staticClass:"windows"},[_c('span',[_vm._v("Windows")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('h2',[_c('span'),_vm._v(" Apple Safari")]),_c('div',{staticClass:"download"},[_c('a',{staticClass:"browserlink",attrs:{"href":"http://www.apple.com/osx/","target":"_blank","data-track":"Apple Safari"}},[_vm._v("DOWNLOAD")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"single"},[_c('li',{staticClass:"mac"},[_c('span',[_vm._v("Mac OS")])])])
}]

export { render, staticRenderFns }